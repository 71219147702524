<template lang="html">
  <nav>
    <div class="links">
      <router-link class="smallcaps" :to="{ name: 'home' }"
        >Sung Tieu</router-link
      >
      <router-link class="smallcaps" :to="{ name: 'home' }">Works</router-link>
      <router-link class="smallcaps" :to="{ name: 'info' }"
        >Information</router-link
      >
    </div>
    <div class="current">
      <div v-if="$store.state.info.news">
        <span class="smallcaps">News</span><br /><br />
        <div v-if="current" v-html="current"></div>
      </div>
    </div>
    <div class="settings-link smallcaps" @click="toggleSettings">Filter</div>
  </nav>
</template>

<script>
export default {
  computed: {
    current() {
      return this.$store.state.site.current;
    },
  },
  methods: {
    toggleSettings() {
      window.scrollTo(0, 0);
      this.$store.state.settings = !this.$store.state.settings;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: calc(4 * var(--lh));
  padding: calc(2 * var(--lh));
  a,
  span {
    padding-right: calc(2 * var(--lh));
  }
  .links {
    grid-column: span 3;
  }
  .current {
    grid-column: span 2;
  }
  .settings-link {
    grid-column: span 1;
    margin-left: auto;
    transition: color 0.5s;
    &:hover {
      cursor: pointer;
      color: #aaa;
    }
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: calc(1 * var(--lh));
    .links {
      grid-column: 1 / 3;
    }
    .current {
      grid-column: span 3;
    }
    .settings-link {
      grid-column: 3 / 4;
      grid-row: 1 / 1;
    }
  }
  @media only screen and (max-width: 768px) {
    .current,
    .links,
    .settings-link {
      grid-column: span 3;
      grid-row: span 1;
    }
    .settings-link {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
      // margin-left:0;
    }
    a,
    span {
      padding-right: 0;
    }
    .links {
      grid-row: 1 / 2;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
